import {ReactElement, useEffect, useMemo} from "react";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import * as Sentry from "@sentry/react";
import PageWrapper from "./components/routing/PageWrapper";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import AccountPage from "./pages/AccountPage";
import WithdrawPage from "./pages/WithdrawPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CookiePolicyPage from "./pages/CookiePolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import DevourPlayLandingPage from "./pages/DevourPlayLandingPage";
import RestaurantSearchResultsPage from "./pages/restaurants/RestaurantSearchResultsPage";
import RestaurantPage, { DEVOUR_RESTAURANT_ID } from "./pages/restaurants/RestaurantPage";
import BuyDPay from "./pages/BuyDPay";
import MenuOrderListPage from "./pages/menu-orders/MenuOrderListPage";
import MenuOrderFailPage from "./pages/menu-orders/MenuOrderFailPage";
import MobileReferAFriendPage from "./pages/MobileReferAFriendPage";
import RestaurantFavoritesPage from "./pages/restaurants/RestaurantFavoritesPage";
import GoVip from "./pages/GoVip";
import MagicLogin from "./pages/MagicLogin";
import MagicRedirectSocial from "./pages/MagicRedirectSocial";
import MagicRedirectRegular from "./pages/MagicRedirectRegular";
import MagicSignUp from "./pages/MagicSignUp";
import ErrorPage from "./pages/ErrorPage";
import "react-loading-skeleton/dist/skeleton.css";
import Landing from "@/pages/Landing";
import {QueryCache, QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {addError} from "@/redux/meta/metaActions";
import {useDispatch} from "react-redux";
import GoFrensPage from "@/pages/GoFrensPage";
//import GoFrensRewardsPage from "@/pages/gofrens/GoFrensRewardsPage";
import GoFrensMyNfts from "@/pages/gofrens/GoFrensMyNfts";
import TheIndustryPassPage from "@/pages/TheIndustryPassPage";
import GoFrensSearchResultsPage from "@/pages/gofrens/GoFrensSearchResultsPage";
import {isDev} from "@/utils/isDev";
import CheckoutPage from "@/pages/CheckoutPage";
import {StatsigProvider} from "statsig-react";
import MobileAffiliatesPage from "@/pages/MobileAffiliatesPage";

// SOLANA
import "@solana/wallet-adapter-react-ui/styles.css";
import {ConnectionProvider, WalletProvider} from "@solana/wallet-adapter-react";
import {type Adapter, WalletAdapterNetwork} from "@solana/wallet-adapter-base";
import {WalletModalProvider} from "@solana/wallet-adapter-react-ui";
import {clusterApiUrl} from "@solana/web3.js";
import {CustomPhantomWalletAdapter} from "@/utils/CustomPhantomWalletAdapter";
import RestaurantMapLandingPage from "@/pages/brandMap/RestaurantMapLandingPage";
import useOverwolfInterop from "./hooks/useOverwolfInterop";
import OverwolfLandingPage from "./pages/overwolf/OverwolfLandingPage";
import OverwolfGoVip from "@/pages/overwolf/OverwolfGoVip";
import MenuOrderSuccessSwitcher from "./components/menuOrder/MenuOrderSuccessSwitcher";
import OverwolfDiscoverPage from "./pages/overwolf/OverwolfDiscoverPage";
import OverwolfGatherPage from "./pages/overwolf/OverwolfGatherPage";

function App(): ReactElement {
    const dispatch = useDispatch();
    // SOLANA
    const network = WalletAdapterNetwork.Mainnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const wallets: Adapter[] = [new CustomPhantomWalletAdapter()];

    const { setupOverwolf, isOnOverwolf } = useOverwolfInterop();

    useEffect(() => {
        setupOverwolf();
    }, []);

    const errorElement =
				<PageWrapper
				    page={<ErrorPage/>}
				    sidebarDockingEnabled={true}
				    showSidebarHamburger={true}
				    authRequired={false}
				/>;
    const queryClient = new QueryClient({
        queryCache: new QueryCache({
            onError: async (error: any) => {

                /*
                 * Wagmi library throws random errors that are not causing actual problems.
                 * So we are ignoring these errors.
                 */
                const ignoreErrors = [
                    "Connector not connected",
                    "connector2.getAccounts is not a function",
                    "1.getAccounts is not a function",
                    "getAccounts is not a function",
                    "connection.connector.getProvider is not a function",
                    "n.connector.getProvider is not a function",
                ];

                if (error && !ignoreErrors.some((msg) => error.message?.includes(msg))) {
                    dispatch(await addError(error));
                }
            },
        }),
    });

    return (
        <ConnectionProvider endpoint={endpoint}>
            <StatsigProvider
                sdkKey={import.meta.env.VITE_STATSIG_CLIENT_API_KEY}
                waitForInitialization={true}
                user={{
                    userID: "",
                }}
            >
                <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                        <QueryClientProvider client={queryClient}>
                            <RouterProvider
                                router={Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
                                    isDev() && {
                                        path: "/demo",
                                        element:
                                        <PageWrapper
                                            page={<ComponentDemoPage/>}
                                            sidebarDockingEnabled={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/",
                                        element:
                                        <PageWrapper
                                            page={isOnOverwolf ? <Navigate to="/overwolf-landing" replace={true}/> : <Landing/>}
                                            sidebarDockingEnabled={false}
                                            showSidebarHamburger={false}
                                            authRequired={false}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/overwolf-landing",
                                        element:
                                        <PageWrapper
                                            page={<OverwolfLandingPage/>}
                                            sidebarDockingEnabled={false}
                                            showSidebarHamburger={false}
                                            showFooter={false}
                                            showMobileStickyFooter={false}
                                            authRequired={false}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/overwolf-discover",
                                        element:
                                        <PageWrapper
                                            page={<OverwolfDiscoverPage/>}
                                            sidebarDockingEnabled={false}
                                            showSidebarHamburger={false}
                                            showFooter={false}
                                            showMobileStickyFooter={false}
                                            authRequired={false}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/overwolf-gather",
                                        element:
                                            <PageWrapper
                                                page={<OverwolfGatherPage/>}
                                                sidebarDockingEnabled={false}
                                                showSidebarHamburger={false}
                                                showFooter={false}
                                                showMobileStickyFooter={false}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/overwolf-go-vip",
                                        element:
                                            <PageWrapper
                                                page={<OverwolfGoVip/>}
                                                sidebarDockingEnabled={false}
                                                showSidebarHamburger={false}
                                                showFooter={false}
                                                showMobileStickyFooter={false}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/log-in",
                                        element:
                                        <PageWrapper
                                            page={<MagicLogin/>}
                                            sidebarDockingEnabled={true}
                                            hideLogoInHamburgerHelper={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/auth-social",
                                        element:
                                        <PageWrapper
                                            page={<MagicRedirectSocial/>}
                                            sidebarDockingEnabled={false}
                                            hideLogoInHamburgerHelper={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/auth-regular",
                                        element:
                                        <PageWrapper
                                            page={<MagicRedirectRegular/>}
                                            sidebarDockingEnabled={false}
                                            hideLogoInHamburgerHelper={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/sign-up",
                                        element:
                                        <PageWrapper
                                            page={<MagicSignUp/>}
                                            sidebarDockingEnabled={true}
                                            hideLogoInHamburgerHelper={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/account",
                                        element:
                                        <PageWrapper
                                            page={<AccountPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/dpay-load",
                                        element:
                                        <PageWrapper
                                            page={<AccountPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/favorites",
                                        element:
                                        <PageWrapper
                                            page={<RestaurantFavoritesPage/>}
                                            sidebarDockingEnabled={true}
                                            showSidebarHamburger={false}
                                            authRequired={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/refer-a-friend",
                                        element:
                                        <PageWrapper
                                            page={<MobileReferAFriendPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/affiliates",
                                        element:
                                        <PageWrapper
                                            page={<MobileAffiliatesPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/gofrens",
                                        element:
                                        <Navigate
                                            to="/gofriends"
                                            replace={true}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/gofriends",
                                        element:
                                        <PageWrapper
                                            page={<GoFrensPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={false}
                                            showSidebarHamburger={false}
                                        />
                                        ,
                                    },
                                    {
                                        path: "/the-industry-pass/",
                                        element:
                                        <PageWrapper
                                            page={<TheIndustryPassPage/>}
                                            sidebarDockingEnabled={true}
                                            authRequired={false}
                                            showSidebarHamburger={false}
                                        />,
                                    },
                                    {
                                        path: "/the-industry-pass/:nftPurchaseId",
                                        element:
                                            <PageWrapper
                                                page={<TheIndustryPassPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={false}
                                                showSidebarHamburger={false}
                                            />,

                                    },
                                    /*{
                                        path: "/gofriends-rewards",
                                        element:
                                            <PageWrapper
                                                page={<GoFrensRewardsPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },*/
                                    {
                                        path: "/gofriends-my-nfts",
                                        element:
                                            <PageWrapper
                                                page={<GoFrensMyNfts/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/gofriends/search-results",
                                        element:
                                            <PageWrapper
                                                page={<GoFrensSearchResultsPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />,

                                    },
                                    {
                                        path: "/go-vip-dashboard",
                                        element:
                                            <PageWrapper
                                                page={<GoVip/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={false}
                                                showSidebarHamburger={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/go-vip",
                                        element:
                                            <Navigate
                                                to={"/go-vip-dashboard"}
                                                replace={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/withdraw",
                                        element:
                                            <PageWrapper
                                                page={<WithdrawPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/buy-dpay",
                                        element:
                                            <Navigate
                                                to="/load-fuel"
                                                replace={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/load-fuel",
                                        element:
                                            <PageWrapper
                                                page={<BuyDPay/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/privacy-policy",
                                        element:
                                            <PageWrapper
                                                page={<PrivacyPolicyPage/>}
                                                sidebarDockingEnabled={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/terms-of-use",
                                        element:
                                            <PageWrapper
                                                page={<TermsOfUsePage/>}
                                                sidebarDockingEnabled={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/devourplay",
                                        element:
                                            <PageWrapper
                                                page={<DevourPlayLandingPage/>}
                                                sidebarDockingEnabled={false}
                                                showSidebarHamburger={false}
                                                showFooter={false}
                                                showMobileStickyFooter={false}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/cookie-policy",
                                        element:
                                            <PageWrapper
                                                page={<CookiePolicyPage/>}
                                                sidebarDockingEnabled={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/brands/:slug",
                                        element:
                                            <PageWrapper
                                                page={<RestaurantMapLandingPage/>}
                                                showSidebarHamburger={false}
                                                sidebarDockingEnabled={false}
                                                showMobileStickyFooter={false}
                                                authRequired={false}
                                                pagePaddingAdjusterClassName="restaurant-map-landing-page-padding-adjuster"
                                                showFooter={false}
                                                themeExcluded={true}
                                                hideLoader={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/restaurants",
                                        element:
                                            <PageWrapper
                                                page={<Landing/>}
                                                sidebarDockingEnabled={false}
                                                showSidebarHamburger={false}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/restaurants/search/:placeId",
                                        element:
                                            <PageWrapper
                                                page={<RestaurantSearchResultsPage/>}
                                                sidebarDockingEnabled={true}
                                                showSidebarHamburger={false}
                                                authRequired={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/restaurants/search",
                                        element:
                                            <Navigate
                                                to="/"
                                                replace={true}
                                            />
                                        ,
                                    },
                                    [
                                        "/restaurants/:state/:cityName/:address/:restaurantName/:restaurantId",
                                        "/restaurants/:state/:cityName/:address/:restaurantName/:restaurantId/:placeId",
                                        "/restaurants/:restaurantId",
                                        "/restaurants/:restaurantId/:placeId",
                                    ].map(p => ({
                                        path: p,
                                        element:
                                            <PageWrapper
                                                page={<RestaurantPage/>}
                                                sidebarDockingEnabled={true}
                                                showSidebarHamburger={false}
                                                authRequired={false}
                                                controlMobileStickyFooterWithRedux={true}
                                            />
                                        ,
                                    })),
                                    {
                                        path: "/checkout/:menuOrderId",
                                        element:
                                            <PageWrapper
                                                page={<CheckoutPage/>}
                                                sidebarDockingEnabled={false}
                                                showSidebarHamburger={false}
                                                showMobileStickyFooter={false}
                                                authRequired={true}
                                                showFooter={false}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/loot-shop",
                                        element:
                                            <Navigate
                                                to={`/restaurants/${DEVOUR_RESTAURANT_ID}`}
                                                replace={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/menu-orders/",
                                        element:
                                            <PageWrapper
                                                page={<MenuOrderListPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/menu-orders/:menuOrderId/success",
                                        element:
                                            <PageWrapper
                                                page={<MenuOrderSuccessSwitcher/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/menu-orders/:menuOrderId/fail",
                                        element:
                                            <PageWrapper
                                                page={<MenuOrderFailPage/>}
                                                sidebarDockingEnabled={true}
                                                authRequired={true}
                                            />
                                        ,
                                    },
                                    {
                                        path: "/*",
                                        element:
                                            <Navigate
                                                to={"/"}
                                                replace={true}
                                            />
                                        ,
                                    },
                                ].flat().map((routeItem) => {
                                    // Prepend errorElement to route item
                                    return {
                                        errorElement,
                                        ...routeItem,
                                    };
                                }))}
                            />
                        </QueryClientProvider>
                    </WalletModalProvider>
                </WalletProvider>
            </StatsigProvider>
        </ConnectionProvider>
    );
}

export default Sentry.withProfiler(App);
