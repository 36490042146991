/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    BulkToggleDevourIQBody,
    BulkToggleDevourIQBodyFromJSON,
    BulkToggleDevourIQBodyToJSON,
    BulkToggleDevourIQResponse,
    BulkToggleDevourIQResponseFromJSON,
    BulkToggleDevourIQResponseToJSON,
    Business,
    BusinessFromJSON,
    BusinessToJSON,
    BusinessAddUserBody,
    BusinessAddUserBodyFromJSON,
    BusinessAddUserBodyToJSON,
    BusinessDeleteUserBody,
    BusinessDeleteUserBodyFromJSON,
    BusinessDeleteUserBodyToJSON,
    BusinessKeys,
    BusinessKeysFromJSON,
    BusinessKeysToJSON,
    BusinessServiceAvailabilityBody,
    BusinessServiceAvailabilityBodyFromJSON,
    BusinessServiceAvailabilityBodyToJSON,
    BusinessType,
    BusinessTypeFromJSON,
    BusinessTypeToJSON,
    BusinessUpdateUserBody,
    BusinessUpdateUserBodyFromJSON,
    BusinessUpdateUserBodyToJSON,
    CreateBusinessBody,
    CreateBusinessBodyFromJSON,
    CreateBusinessBodyToJSON,
    DisableBusinessBody,
    DisableBusinessBodyFromJSON,
    DisableBusinessBodyToJSON,
    FavoriteRestaurantsResponse,
    FavoriteRestaurantsResponseFromJSON,
    FavoriteRestaurantsResponseToJSON,
    GetBusinessBillingResponse,
    GetBusinessBillingResponseFromJSON,
    GetBusinessBillingResponseToJSON,
    GetBusinessOwnActiveStatus,
    GetBusinessOwnActiveStatusFromJSON,
    GetBusinessOwnActiveStatusToJSON,
    GetBusinessOwnArchivedStatus,
    GetBusinessOwnArchivedStatusFromJSON,
    GetBusinessOwnArchivedStatusToJSON,
    GetBusinessOwnEnabledStatus,
    GetBusinessOwnEnabledStatusFromJSON,
    GetBusinessOwnEnabledStatusToJSON,
    GetBusinessOwnMenuStatus,
    GetBusinessOwnMenuStatusFromJSON,
    GetBusinessOwnMenuStatusToJSON,
    GetBusinessOwnPos,
    GetBusinessOwnPosFromJSON,
    GetBusinessOwnPosToJSON,
    GetBusinessOwnSearchCategory,
    GetBusinessOwnSearchCategoryFromJSON,
    GetBusinessOwnSearchCategoryToJSON,
    GetBusinessOwnSort,
    GetBusinessOwnSortFromJSON,
    GetBusinessOwnSortToJSON,
    GetBusinessPermissionsResponse,
    GetBusinessPermissionsResponseFromJSON,
    GetBusinessPermissionsResponseToJSON,
    GetBusinessPermissionsSort,
    GetBusinessPermissionsSortFromJSON,
    GetBusinessPermissionsSortToJSON,
    GetBusinessesChildrenResponse,
    GetBusinessesChildrenResponseFromJSON,
    GetBusinessesChildrenResponseToJSON,
    GetBusinessesOwnResponse,
    GetBusinessesOwnResponseFromJSON,
    GetBusinessesOwnResponseToJSON,
    GetMenuOrdersResponse,
    GetMenuOrdersResponseFromJSON,
    GetMenuOrdersResponseToJSON,
    GetMenuOrdersSort,
    GetMenuOrdersSortFromJSON,
    GetMenuOrdersSortToJSON,
    GetOrderAgainRestaurantsResponse,
    GetOrderAgainRestaurantsResponseFromJSON,
    GetOrderAgainRestaurantsResponseToJSON,
    GetRestaurantsResponse,
    GetRestaurantsResponseFromJSON,
    GetRestaurantsResponseToJSON,
    GetRestaurantsSort,
    GetRestaurantsSortFromJSON,
    GetRestaurantsSortToJSON,
    ImportBusinessBody,
    ImportBusinessBodyFromJSON,
    ImportBusinessBodyToJSON,
    ImportBusinessResponse,
    ImportBusinessResponseFromJSON,
    ImportBusinessResponseToJSON,
    MenuOrderStatus,
    MenuOrderStatusFromJSON,
    MenuOrderStatusToJSON,
    Restaurant,
    RestaurantFromJSON,
    RestaurantToJSON,
    SearchRestaurantsPromosResponse,
    SearchRestaurantsPromosResponseFromJSON,
    SearchRestaurantsPromosResponseToJSON,
    SearchRestaurantsResponse,
    SearchRestaurantsResponseFromJSON,
    SearchRestaurantsResponseToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
    UpdateBulkBusinessActiveBody,
    UpdateBulkBusinessActiveBodyFromJSON,
    UpdateBulkBusinessActiveBodyToJSON,
    UpdateBulkBusinessEnableBody,
    UpdateBulkBusinessEnableBodyFromJSON,
    UpdateBulkBusinessEnableBodyToJSON,
    UpdateBusinessAdmin,
    UpdateBusinessAdminFromJSON,
    UpdateBusinessAdminToJSON,
    UpdateBusinessBillingBody,
    UpdateBusinessBillingBodyFromJSON,
    UpdateBusinessBillingBodyToJSON,
    UpdateBusinessChowlyBody,
    UpdateBusinessChowlyBodyFromJSON,
    UpdateBusinessChowlyBodyToJSON,
    UpdateBusinessImagesBody,
    UpdateBusinessImagesBodyFromJSON,
    UpdateBusinessImagesBodyToJSON,
    UpdateBusinessMarketplaceFeeBody,
    UpdateBusinessMarketplaceFeeBodyFromJSON,
    UpdateBusinessMarketplaceFeeBodyToJSON,
    UpdateBusinessParentBody,
    UpdateBusinessParentBodyFromJSON,
    UpdateBusinessParentBodyToJSON,
    UpdateBusinessSpecialInstructionsBody,
    UpdateBusinessSpecialInstructionsBodyFromJSON,
    UpdateBusinessSpecialInstructionsBodyToJSON,
    UpdateBusinessesImagesBody,
    UpdateBusinessesImagesBodyFromJSON,
    UpdateBusinessesImagesBodyToJSON,
    ValidateDeliveryDistanceResponse,
    ValidateDeliveryDistanceResponseFromJSON,
    ValidateDeliveryDistanceResponseToJSON,
} from '../models';

export interface AddBusinessUserRequest {
    id: string;
    businessAddUserBody?: BusinessAddUserBody;
}

export interface BulkToggleDevourIQRequest {
    bulkToggleDevourIQBody?: BulkToggleDevourIQBody;
}

export interface CreateBusinessRequest {
    createBusinessBody?: CreateBusinessBody;
}

export interface DeleteBusinessUserRequest {
    id: string;
    businessDeleteUserBody?: BusinessDeleteUserBody;
}

export interface DisableBusinessRequest {
    id: string;
    disableBusinessBody?: DisableBusinessBody;
}

export interface GetBusinessRequest {
    id: string;
}

export interface GetBusinessBillingRequest {
    id: string;
}

export interface GetBusinessChildrenRequest {
    id: string;
}

export interface GetBusinessKeysRequest {
    id: string;
}

export interface GetBusinessMenuOrdersRequest {
    id: string;
    status?: Array<MenuOrderStatus>;
    sort?: GetMenuOrdersSort;
    searchValue?: string;
    dateStart?: string;
    dateEnd?: string;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBusinessPermissionsRequest {
    id: string;
    sort?: GetBusinessPermissionsSort;
    order?: SortOrder;
    search?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetBusinessesOwnRequest {
    type?: Array<BusinessType>;
    ids?: Array<string>;
    search?: string;
    searchCategory?: GetBusinessOwnSearchCategory;
    merchant?: string;
    sort?: GetBusinessOwnSort;
    order?: SortOrder;
    isEnabled?: GetBusinessOwnEnabledStatus;
    isArchived?: GetBusinessOwnArchivedStatus;
    isActive?: GetBusinessOwnActiveStatus;
    pos?: GetBusinessOwnPos;
    menus?: GetBusinessOwnMenuStatus;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetOrderAgainRestaurantsRequest {
    placeId?: string;
    handoff?: string;
}

export interface GetRestaurantRequest {
    id: string;
    placeId?: string;
}

export interface GetRestaurantsRequest {
    search?: string;
    merchant?: string;
    sort?: GetRestaurantsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface ImportBusinessesRequest {
    importBusinessBody?: ImportBusinessBody;
}

export interface SearchRestaurantsRequest {
    taxonomyIds?: Array<string>;
    search?: string;
    addressId?: string;
    placeId?: string;
    formattedAddress?: string;
    handoff?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface SearchRestaurantsPromosRequest {
    taxonomyIds?: Array<string>;
    search?: string;
    addressId?: string;
    placeId?: string;
    formattedAddress?: string;
    handoff?: string;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface ToggleArchiveBusinessRequest {
    id: string;
}

export interface ToggleDisableBusinessRequest {
    id: string;
}

export interface UpdateBulkBusinessActiveRequest {
    updateBulkBusinessActiveBody?: UpdateBulkBusinessActiveBody;
}

export interface UpdateBulkBusinessEnableRequest {
    updateBulkBusinessEnableBody?: UpdateBulkBusinessEnableBody;
}

export interface UpdateBusinessAdminRequest {
    id: string;
    updateBusinessAdmin?: UpdateBusinessAdmin;
}

export interface UpdateBusinessBillingRequest {
    id: string;
    updateBusinessBillingBody?: UpdateBusinessBillingBody;
}

export interface UpdateBusinessChowlyRequest {
    id: string;
    updateBusinessChowlyBody?: UpdateBusinessChowlyBody;
}

export interface UpdateBusinessContactRequest {
    id: string;
    createBusinessBody?: CreateBusinessBody;
}

export interface UpdateBusinessImagesRequest {
    id: string;
    updateBusinessImagesBody?: UpdateBusinessImagesBody;
}

export interface UpdateBusinessMarketplaceFeeRequest {
    id: string;
    updateBusinessMarketplaceFeeBody?: UpdateBusinessMarketplaceFeeBody;
}

export interface UpdateBusinessMerchantRequest {
    id: string;
    createBusinessBody?: CreateBusinessBody;
}

export interface UpdateBusinessParentRequest {
    id: string;
    updateBusinessParentBody?: UpdateBusinessParentBody;
}

export interface UpdateBusinessServiceAvailabilityRequest {
    id: string;
    businessServiceAvailabilityBody?: BusinessServiceAvailabilityBody;
}

export interface UpdateBusinessSpecialInstructionsRequest {
    id: string;
    updateBusinessSpecialInstructionsBody?: UpdateBusinessSpecialInstructionsBody;
}

export interface UpdateBusinessUserRequest {
    id: string;
    businessUpdateUserBody?: BusinessUpdateUserBody;
}

export interface UpdateBusinessesImagesRequest {
    updateBusinessesImagesBody?: UpdateBusinessesImagesBody;
}

export interface ValidateDeliveryDistanceRequest {
    id: string;
    restaurant?: string;
}

/**
 * BusinessesApi - interface
 * @export
 * @interface BusinessesApiInterface
 */
export interface BusinessesApiInterface {
    /**
     * Used to add a new user to a business.
     * @param {string} id 
     * @param {BusinessAddUserBody} [businessAddUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    addBusinessUserRaw(requestParameters: AddBusinessUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to add a new user to a business.
     */
    addBusinessUser(requestParameters: AddBusinessUserRequest): Promise<void>;

    /**
     * Used to update the stripe account for this business to receive payment
     * @param {BulkToggleDevourIQBody} [bulkToggleDevourIQBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    bulkToggleDevourIQRaw(requestParameters: BulkToggleDevourIQRequest): Promise<runtime.ApiResponse<BulkToggleDevourIQResponse>>;

    /**
     * Used to update the stripe account for this business to receive payment
     */
    bulkToggleDevourIQ(requestParameters: BulkToggleDevourIQRequest): Promise<BulkToggleDevourIQResponse>;

    /**
     * Used to create new businesses
     * @param {CreateBusinessBody} [createBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    createBusinessRaw(requestParameters: CreateBusinessRequest): Promise<runtime.ApiResponse<Business>>;

    /**
     * Used to create new businesses
     */
    createBusiness(requestParameters: CreateBusinessRequest): Promise<Business>;

    /**
     * Used to remove a user from the business.
     * @param {string} id 
     * @param {BusinessDeleteUserBody} [businessDeleteUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    deleteBusinessUserRaw(requestParameters: DeleteBusinessUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to remove a user from the business.
     */
    deleteBusinessUser(requestParameters: DeleteBusinessUserRequest): Promise<void>;

    /**
     * Used by merchants to permanently remove this business from the system.
     * @param {string} id 
     * @param {DisableBusinessBody} [disableBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    disableBusinessRaw(requestParameters: DisableBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by merchants to permanently remove this business from the system.
     */
    disableBusiness(requestParameters: DisableBusinessRequest): Promise<void>;

    /**
     * Used to get the details of a single business
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessRaw(requestParameters: GetBusinessRequest): Promise<runtime.ApiResponse<Business>>;

    /**
     * Used to get the details of a single business
     */
    getBusiness(requestParameters: GetBusinessRequest): Promise<Business>;

    /**
     * Used to get the Stripe account details for a business.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessBillingRaw(requestParameters: GetBusinessBillingRequest): Promise<runtime.ApiResponse<GetBusinessBillingResponse>>;

    /**
     * Used to get the Stripe account details for a business.
     */
    getBusinessBilling(requestParameters: GetBusinessBillingRequest): Promise<GetBusinessBillingResponse>;

    /**
     * Used to get the children of a single business
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessChildrenRaw(requestParameters: GetBusinessChildrenRequest): Promise<runtime.ApiResponse<GetBusinessesChildrenResponse>>;

    /**
     * Used to get the children of a single business
     */
    getBusinessChildren(requestParameters: GetBusinessChildrenRequest): Promise<GetBusinessesChildrenResponse>;

    /**
     * Used to get the sensitive api keys of a single business
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessKeysRaw(requestParameters: GetBusinessKeysRequest): Promise<runtime.ApiResponse<BusinessKeys>>;

    /**
     * Used to get the sensitive api keys of a single business
     */
    getBusinessKeys(requestParameters: GetBusinessKeysRequest): Promise<BusinessKeys>;

    /**
     * Used by business owners to get a list of all menu orders
     * @param {string} id 
     * @param {Array<MenuOrderStatus>} [status] 
     * @param {GetMenuOrdersSort} [sort] 
     * @param {string} [searchValue] 
     * @param {string} [dateStart] 
     * @param {string} [dateEnd] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessMenuOrdersRaw(requestParameters: GetBusinessMenuOrdersRequest): Promise<runtime.ApiResponse<GetMenuOrdersResponse>>;

    /**
     * Used by business owners to get a list of all menu orders
     */
    getBusinessMenuOrders(requestParameters: GetBusinessMenuOrdersRequest): Promise<GetMenuOrdersResponse>;

    /**
     * Used to get a list of permissions for this specific business that this user has.
     * @param {string} id 
     * @param {GetBusinessPermissionsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {string} [search] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessPermissionsRaw(requestParameters: GetBusinessPermissionsRequest): Promise<runtime.ApiResponse<GetBusinessPermissionsResponse>>;

    /**
     * Used to get a list of permissions for this specific business that this user has.
     */
    getBusinessPermissions(requestParameters: GetBusinessPermissionsRequest): Promise<GetBusinessPermissionsResponse>;

    /**
     * Used to get businesses owned by the current merchant, or all businesses if user is admin
     * @param {Array<BusinessType>} [type] 
     * @param {Array<string>} [ids] 
     * @param {string} [search] 
     * @param {GetBusinessOwnSearchCategory} [searchCategory] 
     * @param {string} [merchant] 
     * @param {GetBusinessOwnSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {GetBusinessOwnEnabledStatus} [isEnabled] 
     * @param {GetBusinessOwnArchivedStatus} [isArchived] 
     * @param {GetBusinessOwnActiveStatus} [isActive] 
     * @param {GetBusinessOwnPos} [pos] 
     * @param {GetBusinessOwnMenuStatus} [menus] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getBusinessesOwnRaw(requestParameters: GetBusinessesOwnRequest): Promise<runtime.ApiResponse<GetBusinessesOwnResponse>>;

    /**
     * Used to get businesses owned by the current merchant, or all businesses if user is admin
     */
    getBusinessesOwn(requestParameters: GetBusinessesOwnRequest): Promise<GetBusinessesOwnResponse>;

    /**
     * Used to get favorite restaurants for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getFavoriteRestaurantsRaw(): Promise<runtime.ApiResponse<FavoriteRestaurantsResponse>>;

    /**
     * Used to get favorite restaurants for user
     */
    getFavoriteRestaurants(): Promise<FavoriteRestaurantsResponse>;

    /**
     * Used to get the nearby restaurants that user has ordered at before.
     * @param {string} [placeId] 
     * @param {string} [handoff] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getOrderAgainRestaurantsRaw(requestParameters: GetOrderAgainRestaurantsRequest): Promise<runtime.ApiResponse<GetOrderAgainRestaurantsResponse>>;

    /**
     * Used to get the nearby restaurants that user has ordered at before.
     */
    getOrderAgainRestaurants(requestParameters: GetOrderAgainRestaurantsRequest): Promise<GetOrderAgainRestaurantsResponse>;

    /**
     * Used to get the details of a single restaurant. Heavier API than `getBusiness`. Requires place ID for distance check. Compares service availability time with menu time.
     * @param {string} id 
     * @param {string} [placeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getRestaurantRaw(requestParameters: GetRestaurantRequest): Promise<runtime.ApiResponse<Restaurant>>;

    /**
     * Used to get the details of a single restaurant. Heavier API than `getBusiness`. Requires place ID for distance check. Compares service availability time with menu time.
     */
    getRestaurant(requestParameters: GetRestaurantRequest): Promise<Restaurant>;

    /**
     * Used to get restaurants by admins with more flexible options than the user-oriented search_restaurants api.
     * @param {string} [search] 
     * @param {string} [merchant] 
     * @param {GetRestaurantsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    getRestaurantsRaw(requestParameters: GetRestaurantsRequest): Promise<runtime.ApiResponse<GetRestaurantsResponse>>;

    /**
     * Used to get restaurants by admins with more flexible options than the user-oriented search_restaurants api.
     */
    getRestaurants(requestParameters: GetRestaurantsRequest): Promise<GetRestaurantsResponse>;

    /**
     * Used to import new businesses
     * @param {ImportBusinessBody} [importBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    importBusinessesRaw(requestParameters: ImportBusinessesRequest): Promise<runtime.ApiResponse<ImportBusinessResponse>>;

    /**
     * Used to import new businesses
     */
    importBusinesses(requestParameters: ImportBusinessesRequest): Promise<ImportBusinessResponse>;

    /**
     * Used to get restaurants by proximity range to customer
     * @param {Array<string>} [taxonomyIds] 
     * @param {string} [search] 
     * @param {string} [addressId] 
     * @param {string} [placeId] 
     * @param {string} [formattedAddress] 
     * @param {string} [handoff] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    searchRestaurantsRaw(requestParameters: SearchRestaurantsRequest): Promise<runtime.ApiResponse<SearchRestaurantsResponse>>;

    /**
     * Used to get restaurants by proximity range to customer
     */
    searchRestaurants(requestParameters: SearchRestaurantsRequest): Promise<SearchRestaurantsResponse>;

    /**
     * Used to get restaurants with promos by proximity range to customer
     * @param {Array<string>} [taxonomyIds] 
     * @param {string} [search] 
     * @param {string} [addressId] 
     * @param {string} [placeId] 
     * @param {string} [formattedAddress] 
     * @param {string} [handoff] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    searchRestaurantsPromosRaw(requestParameters: SearchRestaurantsPromosRequest): Promise<runtime.ApiResponse<SearchRestaurantsPromosResponse>>;

    /**
     * Used to get restaurants with promos by proximity range to customer
     */
    searchRestaurantsPromos(requestParameters: SearchRestaurantsPromosRequest): Promise<SearchRestaurantsPromosResponse>;

    /**
     * Used by admins to archive or unarchive a business (via Merchants page of the Admin Portal).
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    toggleArchiveBusinessRaw(requestParameters: ToggleArchiveBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to archive or unarchive a business (via Merchants page of the Admin Portal).
     */
    toggleArchiveBusiness(requestParameters: ToggleArchiveBusinessRequest): Promise<void>;

    /**
     * Used by admins to disable or enable a business (via Merchants page of the Admin Portal).
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    toggleDisableBusinessRaw(requestParameters: ToggleDisableBusinessRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used by admins to disable or enable a business (via Merchants page of the Admin Portal).
     */
    toggleDisableBusiness(requestParameters: ToggleDisableBusinessRequest): Promise<void>;

    /**
     * Used for Admins to run bulk updates on businesses\' active status.
     * @param {UpdateBulkBusinessActiveBody} [updateBulkBusinessActiveBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBulkBusinessActiveRaw(requestParameters: UpdateBulkBusinessActiveRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used for Admins to run bulk updates on businesses\' active status.
     */
    updateBulkBusinessActive(requestParameters: UpdateBulkBusinessActiveRequest): Promise<void>;

    /**
     * Used for Admins to run bulk updates on businesses\' enabled status.
     * @param {UpdateBulkBusinessEnableBody} [updateBulkBusinessEnableBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBulkBusinessEnableRaw(requestParameters: UpdateBulkBusinessEnableRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used for Admins to run bulk updates on businesses\' enabled status.
     */
    updateBulkBusinessEnable(requestParameters: UpdateBulkBusinessEnableRequest): Promise<void>;

    /**
     * Used to update existing businesses. For admins. Merchants can use `updateBusinessContact`.
     * @param {string} id 
     * @param {UpdateBusinessAdmin} [updateBusinessAdmin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessAdminRaw(requestParameters: UpdateBusinessAdminRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update existing businesses. For admins. Merchants can use `updateBusinessContact`.
     */
    updateBusinessAdmin(requestParameters: UpdateBusinessAdminRequest): Promise<void>;

    /**
     * Used to update the stripe account for this business to receive payment
     * @param {string} id 
     * @param {UpdateBusinessBillingBody} [updateBusinessBillingBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessBillingRaw(requestParameters: UpdateBusinessBillingRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the stripe account for this business to receive payment
     */
    updateBusinessBilling(requestParameters: UpdateBusinessBillingRequest): Promise<void>;

    /**
     * Used to update the Chowly API key for this business
     * @param {string} id 
     * @param {UpdateBusinessChowlyBody} [updateBusinessChowlyBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessChowlyRaw(requestParameters: UpdateBusinessChowlyRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the Chowly API key for this business
     */
    updateBusinessChowly(requestParameters: UpdateBusinessChowlyRequest): Promise<void>;

    /**
     * Used to update existing businesses. For merchants, admins can use `updateBusinessAdmin`.
     * @param {string} id 
     * @param {CreateBusinessBody} [createBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessContactRaw(requestParameters: UpdateBusinessContactRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update existing businesses. For merchants, admins can use `updateBusinessAdmin`.
     */
    updateBusinessContact(requestParameters: UpdateBusinessContactRequest): Promise<void>;

    /**
     * Used to update images for existing businesses
     * @param {string} id 
     * @param {UpdateBusinessImagesBody} [updateBusinessImagesBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessImagesRaw(requestParameters: UpdateBusinessImagesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update images for existing businesses
     */
    updateBusinessImages(requestParameters: UpdateBusinessImagesRequest): Promise<void>;

    /**
     * Used to update marketplace fees for a business.
     * @param {string} id 
     * @param {UpdateBusinessMarketplaceFeeBody} [updateBusinessMarketplaceFeeBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessMarketplaceFeeRaw(requestParameters: UpdateBusinessMarketplaceFeeRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update marketplace fees for a business.
     */
    updateBusinessMarketplaceFee(requestParameters: UpdateBusinessMarketplaceFeeRequest): Promise<void>;

    /**
     * Used to update existing businesses by Merchant.
     * @param {string} id 
     * @param {CreateBusinessBody} [createBusinessBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessMerchantRaw(requestParameters: UpdateBusinessMerchantRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update existing businesses by Merchant.
     */
    updateBusinessMerchant(requestParameters: UpdateBusinessMerchantRequest): Promise<void>;

    /**
     * Used to update the parent entity for this business
     * @param {string} id 
     * @param {UpdateBusinessParentBody} [updateBusinessParentBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessParentRaw(requestParameters: UpdateBusinessParentRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the parent entity for this business
     */
    updateBusinessParent(requestParameters: UpdateBusinessParentRequest): Promise<void>;

    /**
     * Used to update hours and service availability for businesses
     * @param {string} id 
     * @param {BusinessServiceAvailabilityBody} [businessServiceAvailabilityBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessServiceAvailabilityRaw(requestParameters: UpdateBusinessServiceAvailabilityRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update hours and service availability for businesses
     */
    updateBusinessServiceAvailability(requestParameters: UpdateBusinessServiceAvailabilityRequest): Promise<void>;

    /**
     * Used to update the Special Instructions entity for this business
     * @param {string} id 
     * @param {UpdateBusinessSpecialInstructionsBody} [updateBusinessSpecialInstructionsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessSpecialInstructionsRaw(requestParameters: UpdateBusinessSpecialInstructionsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update the Special Instructions entity for this business
     */
    updateBusinessSpecialInstructions(requestParameters: UpdateBusinessSpecialInstructionsRequest): Promise<void>;

    /**
     * Used to update a user\'s permissions on the business.
     * @param {string} id 
     * @param {BusinessUpdateUserBody} [businessUpdateUserBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessUserRaw(requestParameters: UpdateBusinessUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to update a user\'s permissions on the business.
     */
    updateBusinessUser(requestParameters: UpdateBusinessUserRequest): Promise<void>;

    /**
     * Used to bulk update images for a list of businesses
     * @param {UpdateBusinessesImagesBody} [updateBusinessesImagesBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    updateBusinessesImagesRaw(requestParameters: UpdateBusinessesImagesRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Used to bulk update images for a list of businesses
     */
    updateBusinessesImages(requestParameters: UpdateBusinessesImagesRequest): Promise<void>;

    /**
     * Used to validate if specified {placeid} is in valid delivery distance of requested restaurant.
     * @param {string} id 
     * @param {string} [restaurant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessesApiInterface
     */
    validateDeliveryDistanceRaw(requestParameters: ValidateDeliveryDistanceRequest): Promise<runtime.ApiResponse<ValidateDeliveryDistanceResponse>>;

    /**
     * Used to validate if specified {placeid} is in valid delivery distance of requested restaurant.
     */
    validateDeliveryDistance(requestParameters: ValidateDeliveryDistanceRequest): Promise<ValidateDeliveryDistanceResponse>;

}

/**
 * no description
 */
export class BusinessesApi extends runtime.BaseAPI implements BusinessesApiInterface {

    /**
     * Used to add a new user to a business.
     */
    async addBusinessUserRaw(requestParameters: AddBusinessUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBusinessUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessAddUserBodyToJSON(requestParameters.businessAddUserBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to add a new user to a business.
     */
    async addBusinessUser(requestParameters: AddBusinessUserRequest): Promise<void> {
        await this.addBusinessUserRaw(requestParameters);
    }

    /**
     * Used to update the stripe account for this business to receive payment
     */
    async bulkToggleDevourIQRaw(requestParameters: BulkToggleDevourIQRequest): Promise<runtime.ApiResponse<BulkToggleDevourIQResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bulk-toggle-devouriq`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkToggleDevourIQBodyToJSON(requestParameters.bulkToggleDevourIQBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkToggleDevourIQResponseFromJSON(jsonValue));
    }

    /**
     * Used to update the stripe account for this business to receive payment
     */
    async bulkToggleDevourIQ(requestParameters: BulkToggleDevourIQRequest): Promise<BulkToggleDevourIQResponse> {
        const response = await this.bulkToggleDevourIQRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to create new businesses
     */
    async createBusinessRaw(requestParameters: CreateBusinessRequest): Promise<runtime.ApiResponse<Business>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBusinessBodyToJSON(requestParameters.createBusinessBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     * Used to create new businesses
     */
    async createBusiness(requestParameters: CreateBusinessRequest): Promise<Business> {
        const response = await this.createBusinessRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to remove a user from the business.
     */
    async deleteBusinessUserRaw(requestParameters: DeleteBusinessUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBusinessUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessDeleteUserBodyToJSON(requestParameters.businessDeleteUserBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to remove a user from the business.
     */
    async deleteBusinessUser(requestParameters: DeleteBusinessUserRequest): Promise<void> {
        await this.deleteBusinessUserRaw(requestParameters);
    }

    /**
     * Used by merchants to permanently remove this business from the system.
     */
    async disableBusinessRaw(requestParameters: DisableBusinessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disableBusiness.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/disable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DisableBusinessBodyToJSON(requestParameters.disableBusinessBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by merchants to permanently remove this business from the system.
     */
    async disableBusiness(requestParameters: DisableBusinessRequest): Promise<void> {
        await this.disableBusinessRaw(requestParameters);
    }

    /**
     * Used to get the details of a single business
     */
    async getBusinessRaw(requestParameters: GetBusinessRequest): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusiness.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/businesses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     * Used to get the details of a single business
     */
    async getBusiness(requestParameters: GetBusinessRequest): Promise<Business> {
        const response = await this.getBusinessRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get the Stripe account details for a business.
     */
    async getBusinessBillingRaw(requestParameters: GetBusinessBillingRequest): Promise<runtime.ApiResponse<GetBusinessBillingResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusinessBilling.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/businesses/{id}/billing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessBillingResponseFromJSON(jsonValue));
    }

    /**
     * Used to get the Stripe account details for a business.
     */
    async getBusinessBilling(requestParameters: GetBusinessBillingRequest): Promise<GetBusinessBillingResponse> {
        const response = await this.getBusinessBillingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get the children of a single business
     */
    async getBusinessChildrenRaw(requestParameters: GetBusinessChildrenRequest): Promise<runtime.ApiResponse<GetBusinessesChildrenResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusinessChildren.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/businesses/{id}/children`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessesChildrenResponseFromJSON(jsonValue));
    }

    /**
     * Used to get the children of a single business
     */
    async getBusinessChildren(requestParameters: GetBusinessChildrenRequest): Promise<GetBusinessesChildrenResponse> {
        const response = await this.getBusinessChildrenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get the sensitive api keys of a single business
     */
    async getBusinessKeysRaw(requestParameters: GetBusinessKeysRequest): Promise<runtime.ApiResponse<BusinessKeys>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusinessKeys.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/keys`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessKeysFromJSON(jsonValue));
    }

    /**
     * Used to get the sensitive api keys of a single business
     */
    async getBusinessKeys(requestParameters: GetBusinessKeysRequest): Promise<BusinessKeys> {
        const response = await this.getBusinessKeysRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by business owners to get a list of all menu orders
     */
    async getBusinessMenuOrdersRaw(requestParameters: GetBusinessMenuOrdersRequest): Promise<runtime.ApiResponse<GetMenuOrdersResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusinessMenuOrders.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.searchValue !== undefined) {
            queryParameters['searchValue'] = requestParameters.searchValue;
        }

        if (requestParameters.dateStart !== undefined) {
            queryParameters['dateStart'] = requestParameters.dateStart;
        }

        if (requestParameters.dateEnd !== undefined) {
            queryParameters['dateEnd'] = requestParameters.dateEnd;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/menu-orders`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMenuOrdersResponseFromJSON(jsonValue));
    }

    /**
     * Used by business owners to get a list of all menu orders
     */
    async getBusinessMenuOrders(requestParameters: GetBusinessMenuOrdersRequest): Promise<GetMenuOrdersResponse> {
        const response = await this.getBusinessMenuOrdersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get a list of permissions for this specific business that this user has.
     */
    async getBusinessPermissionsRaw(requestParameters: GetBusinessPermissionsRequest): Promise<runtime.ApiResponse<GetBusinessPermissionsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBusinessPermissions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/permissions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessPermissionsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get a list of permissions for this specific business that this user has.
     */
    async getBusinessPermissions(requestParameters: GetBusinessPermissionsRequest): Promise<GetBusinessPermissionsResponse> {
        const response = await this.getBusinessPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get businesses owned by the current merchant, or all businesses if user is admin
     */
    async getBusinessesOwnRaw(requestParameters: GetBusinessesOwnRequest): Promise<runtime.ApiResponse<GetBusinessesOwnResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchCategory !== undefined) {
            queryParameters['searchCategory'] = requestParameters.searchCategory;
        }

        if (requestParameters.merchant !== undefined) {
            queryParameters['merchant'] = requestParameters.merchant;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.isEnabled !== undefined) {
            queryParameters['isEnabled'] = requestParameters.isEnabled;
        }

        if (requestParameters.isArchived !== undefined) {
            queryParameters['isArchived'] = requestParameters.isArchived;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.pos !== undefined) {
            queryParameters['pos'] = requestParameters.pos;
        }

        if (requestParameters.menus !== undefined) {
            queryParameters['menus'] = requestParameters.menus;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/own/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessesOwnResponseFromJSON(jsonValue));
    }

    /**
     * Used to get businesses owned by the current merchant, or all businesses if user is admin
     */
    async getBusinessesOwn(requestParameters: GetBusinessesOwnRequest): Promise<GetBusinessesOwnResponse> {
        const response = await this.getBusinessesOwnRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get favorite restaurants for user
     */
    async getFavoriteRestaurantsRaw(): Promise<runtime.ApiResponse<FavoriteRestaurantsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/restaurants/favorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FavoriteRestaurantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get favorite restaurants for user
     */
    async getFavoriteRestaurants(): Promise<FavoriteRestaurantsResponse> {
        const response = await this.getFavoriteRestaurantsRaw();
        return await response.value();
    }

    /**
     * Used to get the nearby restaurants that user has ordered at before.
     */
    async getOrderAgainRestaurantsRaw(requestParameters: GetOrderAgainRestaurantsRequest): Promise<runtime.ApiResponse<GetOrderAgainRestaurantsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        if (requestParameters.handoff !== undefined) {
            queryParameters['handoff'] = requestParameters.handoff;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/restaurants/order-again`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrderAgainRestaurantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get the nearby restaurants that user has ordered at before.
     */
    async getOrderAgainRestaurants(requestParameters: GetOrderAgainRestaurantsRequest): Promise<GetOrderAgainRestaurantsResponse> {
        const response = await this.getOrderAgainRestaurantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get the details of a single restaurant. Heavier API than `getBusiness`. Requires place ID for distance check. Compares service availability time with menu time.
     */
    async getRestaurantRaw(requestParameters: GetRestaurantRequest): Promise<runtime.ApiResponse<Restaurant>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRestaurant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/restaurants/full/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RestaurantFromJSON(jsonValue));
    }

    /**
     * Used to get the details of a single restaurant. Heavier API than `getBusiness`. Requires place ID for distance check. Compares service availability time with menu time.
     */
    async getRestaurant(requestParameters: GetRestaurantRequest): Promise<Restaurant> {
        const response = await this.getRestaurantRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get restaurants by admins with more flexible options than the user-oriented search_restaurants api.
     */
    async getRestaurantsRaw(requestParameters: GetRestaurantsRequest): Promise<runtime.ApiResponse<GetRestaurantsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.merchant !== undefined) {
            queryParameters['merchant'] = requestParameters.merchant;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/restaurants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRestaurantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get restaurants by admins with more flexible options than the user-oriented search_restaurants api.
     */
    async getRestaurants(requestParameters: GetRestaurantsRequest): Promise<GetRestaurantsResponse> {
        const response = await this.getRestaurantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to import new businesses
     */
    async importBusinessesRaw(requestParameters: ImportBusinessesRequest): Promise<runtime.ApiResponse<ImportBusinessResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportBusinessBodyToJSON(requestParameters.importBusinessBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportBusinessResponseFromJSON(jsonValue));
    }

    /**
     * Used to import new businesses
     */
    async importBusinesses(requestParameters: ImportBusinessesRequest): Promise<ImportBusinessResponse> {
        const response = await this.importBusinessesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get restaurants by proximity range to customer
     */
    async searchRestaurantsRaw(requestParameters: SearchRestaurantsRequest): Promise<runtime.ApiResponse<SearchRestaurantsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.taxonomyIds) {
            queryParameters['taxonomyIds'] = requestParameters.taxonomyIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.addressId !== undefined) {
            queryParameters['addressId'] = requestParameters.addressId;
        }

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        if (requestParameters.formattedAddress !== undefined) {
            queryParameters['formattedAddress'] = requestParameters.formattedAddress;
        }

        if (requestParameters.handoff !== undefined) {
            queryParameters['handoff'] = requestParameters.handoff;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/restaurants/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchRestaurantsResponseFromJSON(jsonValue));
    }

    /**
     * Used to get restaurants by proximity range to customer
     */
    async searchRestaurants(requestParameters: SearchRestaurantsRequest): Promise<SearchRestaurantsResponse> {
        const response = await this.searchRestaurantsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used to get restaurants with promos by proximity range to customer
     */
    async searchRestaurantsPromosRaw(requestParameters: SearchRestaurantsPromosRequest): Promise<runtime.ApiResponse<SearchRestaurantsPromosResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.taxonomyIds) {
            queryParameters['taxonomyIds'] = requestParameters.taxonomyIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.addressId !== undefined) {
            queryParameters['addressId'] = requestParameters.addressId;
        }

        if (requestParameters.placeId !== undefined) {
            queryParameters['placeId'] = requestParameters.placeId;
        }

        if (requestParameters.formattedAddress !== undefined) {
            queryParameters['formattedAddress'] = requestParameters.formattedAddress;
        }

        if (requestParameters.handoff !== undefined) {
            queryParameters['handoff'] = requestParameters.handoff;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/restaurants/search-promos`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchRestaurantsPromosResponseFromJSON(jsonValue));
    }

    /**
     * Used to get restaurants with promos by proximity range to customer
     */
    async searchRestaurantsPromos(requestParameters: SearchRestaurantsPromosRequest): Promise<SearchRestaurantsPromosResponse> {
        const response = await this.searchRestaurantsPromosRaw(requestParameters);
        return await response.value();
    }

    /**
     * Used by admins to archive or unarchive a business (via Merchants page of the Admin Portal).
     */
    async toggleArchiveBusinessRaw(requestParameters: ToggleArchiveBusinessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling toggleArchiveBusiness.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/toggle-archive-business`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to archive or unarchive a business (via Merchants page of the Admin Portal).
     */
    async toggleArchiveBusiness(requestParameters: ToggleArchiveBusinessRequest): Promise<void> {
        await this.toggleArchiveBusinessRaw(requestParameters);
    }

    /**
     * Used by admins to disable or enable a business (via Merchants page of the Admin Portal).
     */
    async toggleDisableBusinessRaw(requestParameters: ToggleDisableBusinessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling toggleDisableBusiness.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/toggle-disable-business`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used by admins to disable or enable a business (via Merchants page of the Admin Portal).
     */
    async toggleDisableBusiness(requestParameters: ToggleDisableBusinessRequest): Promise<void> {
        await this.toggleDisableBusinessRaw(requestParameters);
    }

    /**
     * Used for Admins to run bulk updates on businesses\' active status.
     */
    async updateBulkBusinessActiveRaw(requestParameters: UpdateBulkBusinessActiveRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update-bulk-business-active`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBulkBusinessActiveBodyToJSON(requestParameters.updateBulkBusinessActiveBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used for Admins to run bulk updates on businesses\' active status.
     */
    async updateBulkBusinessActive(requestParameters: UpdateBulkBusinessActiveRequest): Promise<void> {
        await this.updateBulkBusinessActiveRaw(requestParameters);
    }

    /**
     * Used for Admins to run bulk updates on businesses\' enabled status.
     */
    async updateBulkBusinessEnableRaw(requestParameters: UpdateBulkBusinessEnableRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update-bulk-business-enable`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBulkBusinessEnableBodyToJSON(requestParameters.updateBulkBusinessEnableBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used for Admins to run bulk updates on businesses\' enabled status.
     */
    async updateBulkBusinessEnable(requestParameters: UpdateBulkBusinessEnableRequest): Promise<void> {
        await this.updateBulkBusinessEnableRaw(requestParameters);
    }

    /**
     * Used to update existing businesses. For admins. Merchants can use `updateBusinessContact`.
     */
    async updateBusinessAdminRaw(requestParameters: UpdateBusinessAdminRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessAdmin.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/admin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessAdminToJSON(requestParameters.updateBusinessAdmin),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update existing businesses. For admins. Merchants can use `updateBusinessContact`.
     */
    async updateBusinessAdmin(requestParameters: UpdateBusinessAdminRequest): Promise<void> {
        await this.updateBusinessAdminRaw(requestParameters);
    }

    /**
     * Used to update the stripe account for this business to receive payment
     */
    async updateBusinessBillingRaw(requestParameters: UpdateBusinessBillingRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessBilling.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/billing`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessBillingBodyToJSON(requestParameters.updateBusinessBillingBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the stripe account for this business to receive payment
     */
    async updateBusinessBilling(requestParameters: UpdateBusinessBillingRequest): Promise<void> {
        await this.updateBusinessBillingRaw(requestParameters);
    }

    /**
     * Used to update the Chowly API key for this business
     */
    async updateBusinessChowlyRaw(requestParameters: UpdateBusinessChowlyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessChowly.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/chowly`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessChowlyBodyToJSON(requestParameters.updateBusinessChowlyBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the Chowly API key for this business
     */
    async updateBusinessChowly(requestParameters: UpdateBusinessChowlyRequest): Promise<void> {
        await this.updateBusinessChowlyRaw(requestParameters);
    }

    /**
     * Used to update existing businesses. For merchants, admins can use `updateBusinessAdmin`.
     */
    async updateBusinessContactRaw(requestParameters: UpdateBusinessContactRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessContact.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/contact`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBusinessBodyToJSON(requestParameters.createBusinessBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update existing businesses. For merchants, admins can use `updateBusinessAdmin`.
     */
    async updateBusinessContact(requestParameters: UpdateBusinessContactRequest): Promise<void> {
        await this.updateBusinessContactRaw(requestParameters);
    }

    /**
     * Used to update images for existing businesses
     */
    async updateBusinessImagesRaw(requestParameters: UpdateBusinessImagesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessImages.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/images`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessImagesBodyToJSON(requestParameters.updateBusinessImagesBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update images for existing businesses
     */
    async updateBusinessImages(requestParameters: UpdateBusinessImagesRequest): Promise<void> {
        await this.updateBusinessImagesRaw(requestParameters);
    }

    /**
     * Used to update marketplace fees for a business.
     */
    async updateBusinessMarketplaceFeeRaw(requestParameters: UpdateBusinessMarketplaceFeeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessMarketplaceFee.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/marketplace-fee`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessMarketplaceFeeBodyToJSON(requestParameters.updateBusinessMarketplaceFeeBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update marketplace fees for a business.
     */
    async updateBusinessMarketplaceFee(requestParameters: UpdateBusinessMarketplaceFeeRequest): Promise<void> {
        await this.updateBusinessMarketplaceFeeRaw(requestParameters);
    }

    /**
     * Used to update existing businesses by Merchant.
     */
    async updateBusinessMerchantRaw(requestParameters: UpdateBusinessMerchantRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessMerchant.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Merchant", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/merchant`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBusinessBodyToJSON(requestParameters.createBusinessBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update existing businesses by Merchant.
     */
    async updateBusinessMerchant(requestParameters: UpdateBusinessMerchantRequest): Promise<void> {
        await this.updateBusinessMerchantRaw(requestParameters);
    }

    /**
     * Used to update the parent entity for this business
     */
    async updateBusinessParentRaw(requestParameters: UpdateBusinessParentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessParent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/parent`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessParentBodyToJSON(requestParameters.updateBusinessParentBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the parent entity for this business
     */
    async updateBusinessParent(requestParameters: UpdateBusinessParentRequest): Promise<void> {
        await this.updateBusinessParentRaw(requestParameters);
    }

    /**
     * Used to update hours and service availability for businesses
     */
    async updateBusinessServiceAvailabilityRaw(requestParameters: UpdateBusinessServiceAvailabilityRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessServiceAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/service-availability`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessServiceAvailabilityBodyToJSON(requestParameters.businessServiceAvailabilityBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update hours and service availability for businesses
     */
    async updateBusinessServiceAvailability(requestParameters: UpdateBusinessServiceAvailabilityRequest): Promise<void> {
        await this.updateBusinessServiceAvailabilityRaw(requestParameters);
    }

    /**
     * Used to update the Special Instructions entity for this business
     */
    async updateBusinessSpecialInstructionsRaw(requestParameters: UpdateBusinessSpecialInstructionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessSpecialInstructions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/special-instructions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessSpecialInstructionsBodyToJSON(requestParameters.updateBusinessSpecialInstructionsBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update the Special Instructions entity for this business
     */
    async updateBusinessSpecialInstructions(requestParameters: UpdateBusinessSpecialInstructionsRequest): Promise<void> {
        await this.updateBusinessSpecialInstructionsRaw(requestParameters);
    }

    /**
     * Used to update a user\'s permissions on the business.
     */
    async updateBusinessUserRaw(requestParameters: UpdateBusinessUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBusinessUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BusinessUpdateUserBodyToJSON(requestParameters.businessUpdateUserBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to update a user\'s permissions on the business.
     */
    async updateBusinessUser(requestParameters: UpdateBusinessUserRequest): Promise<void> {
        await this.updateBusinessUserRaw(requestParameters);
    }

    /**
     * Used to bulk update images for a list of businesses
     */
    async updateBusinessesImagesRaw(requestParameters: UpdateBusinessesImagesRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Discriminated", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/images`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessesImagesBodyToJSON(requestParameters.updateBusinessesImagesBody),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Used to bulk update images for a list of businesses
     */
    async updateBusinessesImages(requestParameters: UpdateBusinessesImagesRequest): Promise<void> {
        await this.updateBusinessesImagesRaw(requestParameters);
    }

    /**
     * Used to validate if specified {placeid} is in valid delivery distance of requested restaurant.
     */
    async validateDeliveryDistanceRaw(requestParameters: ValidateDeliveryDistanceRequest): Promise<runtime.ApiResponse<ValidateDeliveryDistanceResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling validateDeliveryDistance.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.restaurant !== undefined) {
            queryParameters['restaurant'] = requestParameters.restaurant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/validate_delivery_distance/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateDeliveryDistanceResponseFromJSON(jsonValue));
    }

    /**
     * Used to validate if specified {placeid} is in valid delivery distance of requested restaurant.
     */
    async validateDeliveryDistance(requestParameters: ValidateDeliveryDistanceRequest): Promise<ValidateDeliveryDistanceResponse> {
        const response = await this.validateDeliveryDistanceRaw(requestParameters);
        return await response.value();
    }

}
